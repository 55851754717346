import React, { useState, useEffect } from 'react';
import { get } from '../api/apiServices';
import * as Constants from "../api/constants";
import AddItemsToList from '../services/pos/addItems';

const SearchModal = ({ setSearching, filtered, cart, setCart,setSearch }) => {

    const AddItems=(cart,setCart,item)=>{
        setSearching(false)
        AddItemsToList(cart,setCart,item)
        setSearch('')
    }

    return (
        <div style={{ marginTop: 86, marginLeft: 71 }} tabindex="-1" class="px-5 overflow-y-auto overflow-x-hidden absolute top-0 right-0 left-0 z-50 justify-center items-center w-[46%] md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div class="relative p-4 w-[100%] max-h-full">
                <div class="relative bg-gray-200 shadow">
                    {   
                        filtered &&
                        filtered?.map((item, index) => {
                            return (
                                <div onClick={()=>AddItems(cart,setCart,item)} style={{ borderRadius: 5 }} class="flex items-center justify-between p-1 md:p-1 border-b rounded-t dark:border-gray-400">
                                    <p style={{ paddingLeft: 10, borderRadius: 5 }} class=" text-gray-900 cursor-pointer hover:bg-orange-300 w-full">
                                        {item?.item_name}--[Qty:{item?.available_stock}]--SKU:{item?.sku}
                                    </p>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </div>
    )
};

export default SearchModal;