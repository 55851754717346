
import axios from 'axios';
import { BASE_URL } from './constants';

const api = axios.create({
    baseURL: BASE_URL,
    timeout: 10000,
});

export const get = (url, params) => {
    return api.get(url, params);
};

export const post = (url, data, config) => {
    return api.post(url, data, config);
};

export const put = (url, data) => {
    return api.put(url, data);
};

export const del = (url) => {
    return api.delete(url);
};
