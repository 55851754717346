import toast from 'react-hot-toast';

const setCustomQt = (text, index,cart, setCart) => {
    if (text > 0 || text === "") {
        const nextProducts = [...cart];
        nextProducts[index].quantity = text;
        setCart(nextProducts);

        const AddPrice = [...cart];
        AddPrice[index].totalAmount = text * AddPrice[index].final_price;
        setCart(AddPrice);

        const AddTax = [...cart];
        AddTax[index].totalTax = text * AddTax[index].normalTax;
        setCart(AddTax);

        const AddDiscount = [...cart];
        AddDiscount[index].totalDiscount = text * AddDiscount[index].normalDiscount;
        setCart(AddDiscount);

        const AddUnitcost = [...cart];
        AddUnitcost[index].UnitCost = text * AddUnitcost[index].normalUnitCost;
        setCart(AddUnitcost);
    } else {
        toast.error('Minimum Stock!');
    }
};

export default setCustomQt;