import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { post } from '../../api/apiServices';
import * as Constants from "../../api/constants";

const HoldList = ({ OpenHoldUi, date, AllData, cart,resteAll }) => {

    let DataT = localStorage.getItem("AllData22");
    const LogData = JSON.parse(DataT);
    const [refNumber, setRefNumber] = useState('');
    const config = {
        headers: { 'x-pos-netbees-token': `Basic ${LogData?.token}` }
    };
    let insertID = 0;

    const HoldItem = () => {
        if (refNumber === '') {
            toast.error('Please enter reference number');
        } else {
            post(Constants.POST_HOLD, {
                reference_id: refNumber,
                sales_date: date,
                sales_status: "Final",
                customer_id: AllData.customer_id,
                discount_to_all_input: 0.00,
                discount_to_all_type: "in_percentage",
                tot_discount_to_all_amt: 0.00,
                subtotal: AllData.lastTotalPrice.toFixed(2),
                round_off: 0.00,
                grand_total: AllData.lastTotalPrice.toFixed(2),
                pos: 1,
                other_charges_amt: 0.00,
                warehouse_id: AllData.warehouse_id,
                company_id: LogData.company_id
            }, config)
                .then(({ data }) => {
                    console.log(data.result)
                    insertID = data.result.insertId;
                    post_hold_Items();
                })
                .catch(function (error) {
                    alert('Error' + error);
                });
        }
    };


    const post_hold_Items = () => {
        const promises = cart.map((item) => {
            const A = item.sales_price;
            let pricePerunit = A.toFixed(2);
            const B = item.totalTax;
            let TaxAmount = B.toFixed(2);
            const C = item.totalDiscount;
            let DiscountAmount = C.toFixed(2);
            const D = item.normalUnitCost;
            let nUnitCost = D.toFixed(2);
            const E = item.UnitCost;
            let tUnitCost = E.toFixed(2);
            post(Constants.POST_HOLD_ITEMS, {
                hold_id: insertID,
                sales_status: "Final",
                item_id: item.id,
                description: item.description,
                sales_qty: item.quantity,
                price_per_unit: item.sales_price,
                tax_type: item.tax_type,
                tax_id: item.tax_id,
                tax_amt: TaxAmount,
                discount_type: item.discount_type,
                discount_input: item.discount,
                discount_amt: DiscountAmount,
                unit_total_cost: nUnitCost,
                total_cost: tUnitCost,
                warehouse_id: AllData.warehouse_id,
            }, config);
        })
        Promise.all(promises)
            .then(({ data }) => {
                console.log(data);
                resteAll();
                toast.success('Hold items Successfully Saved');
                OpenHoldUi(false);
            })
            .catch(function (error) {
                alert('Error - SaleItems' + error);
                toast.error('Something went wrong please try again');
            })

    };

    return (
        <div className="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[400px]">
                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start ">
                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.05 4.575a1.575 1.575 0 1 0-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 0 1 3.15 0v1.5m-3.15 0 .075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 0 1 3.15 0V15M6.9 7.575a1.575 1.575 0 1 0-3.15 0v8.175a6.75 6.75 0 0 0 6.75 6.75h2.018a5.25 5.25 0 0 0 3.712-1.538l1.732-1.732a5.25 5.25 0 0 0 1.538-3.712l.003-2.024a.668.668 0 0 1 .198-.471 1.575 1.575 0 1 0-2.228-2.228 3.818 3.818 0 0 0-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0 1 16.35 15m.002 0h-.002" />
                                    </svg>
                                </div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left ">
                                    <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Hold Invoice ?</h3>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">Same Reference will replace the old list if exist!!</p>
                                    </div>
                                    <div class="w-[100%]">
                                        <input
                                            onChange={(e) => setRefNumber(e.target.value)}
                                            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-[100%] py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" placeholder='Please enter the reference number' type="text" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            <button onClick={HoldItem} type="button" className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">OK</button>
                            <button onClick={() => OpenHoldUi(false)} type="button" className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HoldList;