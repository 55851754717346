import React, { useEffect, useState } from "react";
import WareHouse from "../dropDowns/wareHouse";
import Customers from "../dropDowns/customers";
import ProdutSearch from "../productSearch";
import { useTranslation } from 'react-i18next';
import Languages from "../dropDowns/languages";
import { IMAGE_URL } from "../../api/constants";
import NoImage from "../../images/noIMG.jpg";
import { MdDeleteForever } from "react-icons/md";
import toast, { Toaster } from 'react-hot-toast';
import setCustomPrice from '../../services/pos/customPrice';
import onSubtract from "../../services/pos/qtMinus";
import onAdd from "../../services/pos/qtAdd";
import setCustomQt from "../../services/pos/customQt";
import SearchModal from "../searchModal";
import { IoHandLeftSharp } from "react-icons/io5";
import { RiCheckboxMultipleFill } from "react-icons/ri";
import { IoMdCash } from "react-icons/io";
import PaymentAll from "../payments/paymentAll";
import LeftUiPayment from "../payments/leftPayemnt";
import PrintButton from "../../services/pos/printInvoice";
import FinalSave from "../../services/pos/finalSave";
import { DateAndTime } from "../dateAndTime";
import HoldList from "./holdUi";

const LeftUi = ({ setDefaultwarehouseId, changeLanguage, cart, setCart, defaultwarehouseId, currency_code }) => {

    useEffect(() => {
        console.log(cart);
        DateAndTime((dt) => {
            setDate(dt.date)
        })
    }, [cart]);

    const { t } = useTranslation();

    const ClearAll = () => {
        setCart([]);
        resteAll();

    }

    const removeProduct = async (item) => {
        const newCart = cart.filter(cartItem => cartItem.id !== item.id);
        setCart(newCart);
    };

    const [searching, setSearching] = useState(false);
    const [filtered, setFiltered] = useState([]);
    const [search, setSearch] = useState("");
    const [customer_id, setCustomer_id] = useState(1);
    const [balance, setBalance] = useState(0);
    let totalQuantity = 0;
    let totalPrice = 0;
    let totalTaxvalue = 0;
    let totalDiscount = 0;
    let lastTotalPrice = 0;
    let Qtc = 0;

    cart?.forEach((item) => {
        totalQuantity += +item?.quantity;
        Qtc = item.quantity;
        totalPrice += +item?.totalAmount;
        totalTaxvalue += item?.totalTax;
        if (isNaN(item?.totalDiscount)) {
            totalDiscount = 0;
        } else {
            totalDiscount += item?.totalDiscount;
        }
        lastTotalPrice += item?.UnitCost;
    });

    useEffect(() => {
        setBalance(lastTotalPrice?.toFixed(2));

        if (cart.length === 0) {
            setAmounts('');
            setChange(0);
        }

    }, [cart]);
    const [payAll, setPayAll] = useState(false);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState('');
    const paymentNote = "";
    const [amount, setAmounts] = useState('');
    const [change, setChange] = useState(0);

    const PayAll = () => {
        if (cart.length === 0) {
            toast.error('Please Add Product First');

        }
        else if (balance !== "0.00") {
            toast.error('Should Pay complete Amount');
        }
        else {
            setLoading(true);
            FinalSave(paymentNote, AllData, cart, date, resteAll);
        }
    };

    const AllData = {
        customer_id: customer_id,
        warehouse_id: defaultwarehouseId,
        totalQuantity: totalQuantity,
        totalPrice: totalPrice,
        totalTaxvalue: totalTaxvalue,
        totalDiscount: totalDiscount,
        lastTotalPrice: lastTotalPrice,

    };

    const resteAll = () => {
        setCustomer_id(1);
        setSearch("");
        setFiltered([]);
        setAmounts('');
        setCart([]);
        setChange(0);

        // setPayAll(false);
    };

    const [openHold, setOpenHold] = useState(false)

    const OpenHoldUi = () => {
        if (cart.length === 0) {
            toast.error('Please Add Product First');
        } else {
            setOpenHold(true);
        }
    }

    return (

        <div className="w-full lg:w-[40%] h-[100%]">
            {
                payAll &&
                <PaymentAll
                    setPayAll={setPayAll}
                    AllData={AllData}
                    cart={cart}
                    resteAll={resteAll}
                />
            }
            {
                openHold &&
                <HoldList
                    OpenHoldUi={setOpenHold}
                    date={date}
                    AllData={AllData}
                    cart={cart}
                    resteAll={resteAll}
                />
            }

            <Toaster
                position="top-center"
            />
            <div className="flex flex-row items-center justify-between px-5 mt-5 h-[10%]">
                <WareHouse
                    setDefaultwarehouseId={setDefaultwarehouseId}
                />
                <Customers
                    setCustomer_id={setCustomer_id}
                    customer_id={customer_id}
                />

                {/* <Languages
                    changeLanguage={changeLanguage}
                /> */}
            </div>
            <div className="flex flex-row items-center justify-between px-5 mt-2">
                <ProdutSearch
                    defaultwarehouseId={defaultwarehouseId}
                    setSearching={setSearching}
                    setFiltered={setFiltered}
                    setSearch={setSearch}
                    search={search}
                />
                {
                    searching &&
                    <SearchModal
                        setSearching={setSearching}
                        filtered={filtered}
                        cart={cart}
                        setCart={setCart}
                        setSearch={setSearch}
                    />
                }

            </div>


            <div className=" bg-[#337AB7] p-1 flex flex-row justify-between items-center mb-1   ">
                <div className="items-center w-[30%]">
                    <p className=" font-semibold text-sm text-white text-center">Item Name</p>
                </div>
                <div className="items-center w-[19%]">
                    <p className=" font-semibold text-sm text-white text-center">Quantity</p>
                </div>
                <div className="items-center w-[19%]">
                    <p className=" font-semibold text-sm text-white text-center">Price</p>
                </div>
                <div className="items-center w-[19%]">
                    <p className=" font-semibold text-sm text-white text-center">Total</p>
                </div>
                <div className="items-center w-[10%]">
                    <p className=" font-semibold text-sm text-white text-center">X</p>
                </div>
            </div>

            <div className="px-1 mt-1 overflow-y-auto h-[230px]">
                {
                    cart &&
                    cart?.map((items, index) => {
                        return (
                            <div style={{ borderColor: '#123', borderBottomWidth: 1 }} className=" bg-orange-100 p-1 flex flex-row justify-between items-center">
                                <div className="flex flex-row items-center w-[31%]">
                                    <span className="ml-4 font-semibold text-sm">{items?.item_name}</span>
                                </div>
                                <div className="w-[19%] flex justify-between">

                                    <button
                                        type="button"
                                        onClick={() => onSubtract(items, index, cart, setCart)}
                                        style={{ borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}
                                        className=" flex-shrink-0 bg-gray-700 dark:hover:bg-red-700 dark:border-gray-600 hover:bg-red-700 inline-flex items-center justify-center border border-gray-300 h-8 w-[25%] focus:ring-gray-100 dark:focus:ring-gray-700  focus:outline-none">
                                        <svg className="w-2.5 h-2.5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16" />
                                        </svg>
                                    </button>
                                    <input
                                        type="number"
                                        className="flex-shrink-0 bg-white text-gray-900 border-0 bg-transparent text-sm font-normal focus:outline-none focus:ring-0 w-[50%] text-center"
                                        placeholder="1"
                                        style={{ borderWidth: 1, height: 33, marginTop: 0.5 }}
                                        value={items?.quantity}
                                        onChange={(text) => setCustomQt(text.target.value, index, cart, setCart)}
                                    />
                                    <button
                                        type="button"
                                        onClick={() => onAdd(items, index, cart, setCart)}
                                        style={{ borderTopRightRadius: 8, borderBottomRightRadius: 8 }}
                                        className="flex-shrink-0 bg-gray-700 dark:hover:bg-green-700 dark:border-gray-600 hover:bg-green-700 inline-flex items-center justify-center border border-gray-300 h-8 w-[25%] focus:ring-gray-100 dark:focus:ring-gray-700 focus:outline-none">
                                        <svg className="w-2.5 h-2.5 text-white " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="w-[19%] flex justify-between">
                                    <input
                                        type="number"
                                        style={{ height: 33, marginTop: 0.5, paddingLeft: 7 }}
                                        value={items?.totalAmount.toFixed(2)}
                                        onChange={(text) => setCustomPrice(text.target.value, index, cart, setCart)}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 w-full focus:border-blue-500 block  dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                </div>
                                <div className="w-[19%] flex justify-between">
                                    <input
                                        disabled={true}
                                        style={{ height: 33, marginTop: 0.5, paddingLeft: 7 }}
                                        value={items?.UnitCost.toFixed(2)}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 w-full focus:border-blue-500 block  dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                </div>
                                <div className="w-[8%] flex justify-between ml-1">
                                    <button onClick={() => removeProduct(items)} class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-2 border border-red-700 rounded">
                                        <MdDeleteForever />
                                    </button>
                                </div>

                            </div>
                        )

                    })
                }

            </div>

            <div className="px-5 mt-5">
                <div className="py-4 rounded-md shadow-lg">
                    <div className=" px-4 flex justify-between ">
                        <span className={`font-semibold text-sm ${t('fontSize')}`}>{t('Quantity')}</span>
                        <span className="font-bold">{totalQuantity}</span>
                    </div>
                    <div className=" px-4 flex justify-between ">
                        <span className={`font-semibold text-sm ${t('fontSize')}`}>{t('Subtotal')}</span>
                        <span className="font-bold">{currency_code} {totalPrice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    </div>
                    {/* <div className=" px-4 flex justify-between ">
                        <span className={`font-semibold text-sm ${t('fontSize')}`}>{t('Discount')}</span>
                        <span className="font-bold">- {currency_code} {totalDiscount.toFixed(2)}</span>
                    </div>
                    <div className=" px-4 flex justify-between ">
                        <span className={`font-semibold text-sm ${t('fontSize')}`}>{t('Sales_Tax')}</span>
                        <span className="font-bold">{currency_code} {totalTaxvalue.toFixed(2)}</span>
                    </div> */}
                    <div className="border-t-2 mt-3 py-2 px-4 flex items-center justify-between">
                        <span className={`font-semibold text-2xl ${t('fontSize')}`}>{t('Total')}</span>
                        <span className="font-bold text-2xl">{currency_code} {lastTotalPrice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    </div>
                    <LeftUiPayment
                        AllData={AllData}
                        balance={balance}
                        setBalance={setBalance}
                        setAmounts={setAmounts}
                        amounts={amount}
                        setChange={setChange}
                        change={change}
                    />
                </div>
            </div>

            <div className="px-5 mt-5">
                <div className="flex flex-raw">
                    <button onClick={ClearAll} className={` w-[28%] inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden font-semibold cursor-pointer hover:bg-red-200 focus:ring-gray-300 px-4 rounded-md bg-red-100 text-red-500 ${t('fontSize')}`}>
                        {t('Clear_All')}
                    </button>
                    {/* <button onClick={OpenHoldUi} class="relative w-[20%] inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-pink-500 to-orange-400 group-hover:from-pink-500 group-hover:to-orange-400 hover:text-white">
                        <span class="relative text-center inline-flex px-4 py-4 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                            <IoHandLeftSharp style={{ margin: 2 }} /> HOLD
                        </span>
                    </button> */}
                    <PrintButton
                        SaveAndPrint={PayAll}
                        balance={balance}
                        setPayAll={setPayAll}
                        cart={cart}
                    />

                </div>
            </div>

            <div className="px-5 mt-5">

            </div>

        </div>
    )
}

export default LeftUi;