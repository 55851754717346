    import toast from 'react-hot-toast';    
    const onSubtract = (item, index,cart, setCart) => {
        const nextProducts = [...cart];

        if (nextProducts[index].quantity > 1) {
            --nextProducts[index].quantity;
            setCart(nextProducts);

            const AddPrice = [...cart];
            AddPrice[index].totalAmount -= AddPrice[index].final_price;
            setCart(AddPrice);

            const AddDiscount = [...cart];
            AddDiscount[index].totalDiscount -= AddDiscount[index].normalDiscount;
            setCart(AddDiscount);

            const AddTax = [...cart];
            AddTax[index].totalTax -= AddTax[index].normalTax;
            setCart(AddTax);

            const AddUnitcost = [...cart];
            AddUnitcost[index].UnitCost -= AddUnitcost[index].normalUnitCost;
            setCart(AddUnitcost);
        }
        else {
            toast.error('Minimum Stock!');
        }
    };

    export default onSubtract;