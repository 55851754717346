import React, { useEffect, useState } from "react";
import { IMAGE_URL } from "../../api/constants";
import NoImage from "../../images/noIMG.jpg";
import { RotatingSquare } from 'react-loader-spinner';
import Categories from '../dropDowns/categories';
import Brands from '../dropDowns/brands';
import { useTranslation } from 'react-i18next';
import AddItemsToList from "../../services/pos/addItems";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import Languages from "../dropDowns/languages";
import HoldList from "./holdList";
import { MdScreenshotMonitor } from "react-icons/md";

const ProductsUi = ({ products, currency_code, loading, setCategoryId, setBrandId, m_logD, cart, setCart, changeLanguage, holdList, holdcount }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [isFullScreen, setIsFullScreen] = useState(false);

    const handleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen().then(() => setIsFullScreen(true));
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen().then(() => setIsFullScreen(false));
            }
        }
    };

    const setProduct = (item) => {
        AddItemsToList(cart, setCart, item);
    };

    const LogOut = () => {
        localStorage.removeItem('AllData22');
        navigate("/");
    }

    var TimeMoment = moment().utcOffset('+05:30')
        .format('HH:mm');

        var DateMoment = moment().utcOffset('+05:30')
        .format('YYYY-MM-DD');

    return (
        <div className="w-full lg:w-[60%] min-h-screen shadow-lg " >
            <div className="flex flex-row justify-between items-center px-5 mt-5">
                <div className="text-gray-800">
                    <div className={`font-bold text-xl ${t('fontSize2')}`}>{t('header')}</div>
                    <span className="text-xs">User: {m_logD?.full_name}</span>
                </div>
                <div className="flex items-center">

                    {/* <HoldList
                        holdList={holdList}
                        holdcount={holdcount}
                        cart={cart}
                        setCart={setCart}
                    /> */}
                    <div className="text-sm text-center mr-4">
                        <div className={`font-light text-gray-500 ${t('fontSize')}`}>{t('date')}</div>
                        <span className="font-semibold">{DateMoment}</span>
                    </div>
                    <div className="text-sm text-center mr-4">
                        <div className={`font-light text-gray-500 ${t('fontSize')}`}>{t('Time')}</div>
                        <span className="font-semibold">{TimeMoment}</span>
                    </div>
                    
                    <div>
                        <button
                            onClick={handleFullScreen}
                            className={`px-4 py-1.5 ml-2 mr-2 bg-gray-400 text-white font-semibold rounded text-[22px]`}
                        >
                            <MdScreenshotMonitor /></button>
                    </div>

                    <Languages
                        changeLanguage={changeLanguage}
                    />
                    <div>
                        <button
                            onClick={LogOut}
                            className={`px-4 py-1.5 ml-2 bg-gray-200 text-gray-800 font-semibold rounded ${t('fontSize')}`}
                        >
                            {t('Sign_Out')}</button>
                    </div>

                </div>
            </div>

            <div className="grid grid-cols-6 gap-2 px-2 mt-5 overflow-y-auto h-[60%]" >
                {
                    loading ?
                        <RotatingSquare
                            visible={loading}
                            height="80"
                            width="80"
                            color="#006AB8"
                            ariaLabel="rotating-square-loading"
                            radius="12.5"
                            wrapperStyle={{ width: "60%", height: "75%", position: 'absolute', backgroundColor: '#fff', alignItems: 'center', justifyContent: 'center', zIndex: 999 }}
                            wrapperClass="grid-wrapper "
                        /> : <>
                            {
                                products &&
                                products?.map((items, index) => {
                                    return (
                                        <div style={{ backgroundColor: "#E6FFEA" }} key={index} onClick={() => setProduct(items)} className=" cursor-pointer px-3 py-3 flex flex-col border border-gray-200 rounded-md h-32 justify-between">
                                            <div className="flex flex-row justify-between items-center" >
                                                <img src={items?.item_image ? IMAGE_URL + items?.item_image : NoImage} className=" h-7 w-7 object-cover rounded-md" alt="" />
                                                <span className="font-light text-xs text-gray-600">QTY: {items?.stock}</span>
                                            </div>
                                            <div>
                                                <div className="font-bold text-gray-800 text-center" style={{ fontSize: 11 }}>{items?.item_name?.slice(0, 20)}</div>
                                                <div className="font-bold text-base text-blue-500 text-center" >{currency_code} {items?.final_price}</div>
                                                {/* <span className="font-light text-sm text-gray-600">({items?.item_code})</span> */}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </>
                }


            </div>
            <div className="mt-5 flex flex-row px-5" style={{ width: '100%' }}>
                <Categories
                    setCategoryId={setCategoryId}
                />
                {/* <Brands 
                    setBrandId={setBrandId}
                /> */}
            </div>
        </div>

    );


};

export default ProductsUi;