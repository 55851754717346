import React, { Fragment } from 'react';
import Login from './containers/Login/inedx';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CompanyLogin from './containers/CompanyLogin';
import PosScreeen from './containers/PosUi';
import './App.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './languages/i18n';


function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Fragment>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/company" element={<CompanyLogin />} />
            <Route path="/pos" element={<PosScreeen />} />
          </Routes>
        </Router>
      </Fragment>
    </I18nextProvider>

  );
}

export default App;
