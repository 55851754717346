

import { get, post } from "../../api/apiServices";
import * as Constants from "../../api/constants";
import toast, { Toaster } from 'react-hot-toast';
import PosDelete from "./posDeleteSale";
import moment from 'moment';

const PosPayment = (paymentNote,AllData,LogData, config, C_Details, insertID, date) => {

    var TimeMoment = moment().utcOffset('+05:30')
        .format('HH:mm:ss');

    post(Constants.POST_SALE_PAYMENT, {
        saleId: insertID,
        payment_date: date,
        payment_type: "Cash",
        payment: AllData.lastTotalPrice.toFixed(2),
        payment_note: paymentNote, //set done by cach
        created_date: date,
        created_time: TimeMoment,
        created_by: LogData.username, //set created_by
        system_ip: '192.168.10.12', //set after all
        system_name: "REACT-POS",
        change_return: 0,
        status: 1, //FIX
    }, config)
        .then(({ data }) => {
            console.log(data);
        })
        .catch(function (error) {
            alert('Error - PosPayment' + error);
            toast.error('Something went wrong please try again');
            PosDelete(LogData, config, C_Details);
        });

};

export default PosPayment;