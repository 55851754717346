export const BASE_URL = 'https://posdemo.netbees.com.sg/billing/api/';
export const IMAGE_URL = 'https://posdemo.netbees.com.sg/'  //replace this also

//GET END POINTS
export const GET_COMPANY_DETAILS = BASE_URL+"defaultcompanydata/";
export const GET_ITEMS = BASE_URL+"items/";
export const GET_WAREHOUSE = BASE_URL+"warehouse/";
export const GET_DEFAULT_WAREHIUSE = BASE_URL+"defaultwarehouse/";
export const GET_INVOICE = BASE_URL+"getpdfinvoice/";
export const GET_CUSTOMERS = BASE_URL+"customers/";
export const GET_CATEGORY = BASE_URL+"category/";
export const GET_BRANDS = BASE_URL+"brands/";
export const GET_SEARCH_ITEMS = BASE_URL+"searchitem/";
export const GET_HOLD_ALL_ITEMS = BASE_URL+"getAllholds/";
export const GET_HOLD_ITEMS = BASE_URL+"getholdItems/";

//POST END POINTS
export const POST_LOGIN = BASE_URL+"login/";
export const POST_COMPANIES_LIST = BASE_URL+"allowedCompanies/";
export const POST_COMPANY_LOGIN = BASE_URL+ "companylogin/";
export const POST_SALE = BASE_URL+"sale/"; //post sale
export const POST_SALE_ITEM = BASE_URL+"saleitem/"; //post sale item
export const POST_SALE_PAYMENT = BASE_URL+"salepayment/"; //post sale payments
export const POST_DELETE_SALE = BASE_URL+"deletesale/"; 
export const POST_HOLD = BASE_URL+"hold/";
export const POST_HOLD_ITEMS = BASE_URL+"holditem/"; 
export const POST_DELETE_HOLD = BASE_URL+"deleteHold/";