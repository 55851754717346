import React, { useState, useEffect } from "react";
import toast, { Toaster } from 'react-hot-toast';
import { get, post } from "../../api/apiServices";
import * as Constants from "../../api/constants";
import { useNavigate } from "react-router-dom";
import { RotatingSquare } from 'react-loader-spinner';
import MultypleCompanys from "../../components/multypleCompanys";

export let isLoged = false;

const Login = () => {
    const navigate = useNavigate();
    const [u_name, setU_name] = useState("");
    const [passW, setPasW] = useState("");
    const [showLogin, setShowLogin] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [companys, setCompanys] = useState([]);

    const LoginCheck = () => {
        let cID = '';
        let Token = '';
        let Ids = '';
        if (u_name === "") {
            toast.error('Please enter your username');
        } else if (passW === "") {
            toast.error('Please enter your password');
        } else {
            setLoading(true);
            post(Constants.POST_LOGIN, {
                username: u_name,
                password: passW
            }).then(({ data }) => {
                console.log(data.result);
                toast.success('Successfully logged!');
                cID = data.result.company_id;
                Ids = data.result.allowed_companies;
                Token = data.result.token;
                localStorage.setItem('Token' , Token);
                localStorage.setItem('AllData22', JSON.stringify(data.result));
                CompanyLog(Ids)
                isLoged = true;
            }).catch(function (error) {
                toast.error('Invalid username or password');
                setLoading(false);
            });
        }
    };

    const CompanyLog = (Ids) => {
        post(Constants.POST_COMPANIES_LIST, {
            ids: Ids
        }).then(({ data }) => {
            setCompanys(data.result);
            setShowLogin(false);
            setLoading(false);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const submitHandler = (text) => {
        setU_name(text.target.value);
    };

    const passwordHandler = (text) => {
        setPasW(text.target.value);
    }



    return (
        <section style={{
            backgroundImage: "url(" + "https://img.freepik.com/premium-photo/point-sale_818771-1126.jpg?w=740" + ")",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: "100%",
            height: "100%"
        }}>
            <Toaster
                position="top-center"
            />
            {
                loading &&
                <RotatingSquare
                    visible={loading}
                    height="70"
                    width="70"
                    color="#4fa94d"
                    ariaLabel="rotating-square-loading"
                    radius="12.5"
                    wrapperStyle={{ width: "100%", height: "100%", position: 'absolute', backgroundColor: 'rgb(0,0,0, 0.4)', alignItems: 'center', justifyContent: 'center' }}
                    wrapperClass="grid-wrapper"
                />
            }

            {
                showLogin ? (<div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                    <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                        <img className=" w-96 mr-2" src="https://posdemo.netbees.com.sg/uploads/1/logo/1671261805.png" alt="logo" />
                    </a>
                    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Sign in to your account
                            </h1>
                            <form className="space-y-4 md:space-y-6" >

                                <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                                <input className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com"
                                    onChange={submitHandler}
                                    value={u_name}
                                    type="text"
                                />

                                <div>
                                    <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                    <input placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        value={passW}
                                        onChange={passwordHandler}
                                        type={
                                            showPassword ? "text" : "password"
                                        }

                                    />
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-start">
                                        <div className="flex items-center h-5">
                                            <input id="check" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                                                value={showPassword}
                                                onChange={() =>
                                                    setShowPassword((prev) => !prev)
                                                }
                                            />
                                        </div>
                                        <div className="ml-3 text-sm">
                                            <label for="remember" className="text-gray-500 dark:text-gray-300">show password</label>
                                        </div>
                                    </div>
                                    {/* <a href="#" className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot password?</a> */}
                                </div>
                                {/* <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                Don’t have an account yet? <a href="#" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</a>
                            </p> */}
                            </form>
                            <button onClick={LoginCheck} className="w-full bg-green-700 text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Sign in</button>

                        </div>
                    </div>
                </div>) : (
                    <><MultypleCompanys
                            companys={companys} 
                            u_name={u_name}
                            passW={passW}
                            setLoading={setLoading}
                            /></>
                )
            }


        </section>
    );
};

export default Login;