import React, { useState, useEffect, useRef } from 'react';
import Select from "react-dropdown-select";
import { get } from '../../api/apiServices';
import * as Constants from "../../api/constants";
import { GrPowerReset } from "react-icons/gr";
import { useTranslation } from 'react-i18next';

const Categories = ({ setCategoryId }) => {

    const { t } = useTranslation();

    const [categories, setCategories] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let DataT = localStorage.getItem("AllData22");
        const LogData = JSON.parse(DataT);
        const config = {
            headers: { 'x-pos-netbees-token': `Basic ${LogData.token}` }
        };
        fetchcategory(config, LogData);

    }, []);

    const fetchcategory = async (config, LogData) => {
        get(Constants.GET_CATEGORY + LogData.company_id, config)
            .then(({ data }) => {
                console.log(data.result)
                setCategories(data.result)
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false))
    };



    const ChangeValue = (items) => {
        setCategoryId(items.id)
    };

    const ResetAll = () => {
        setCategories([]);
        let DataT = localStorage.getItem("AllData22");
        const LogData = JSON.parse(DataT);
        const config = {
            headers: { 'x-pos-netbees-token': `Basic ${LogData.token}` }
        };
        fetchcategory(config, LogData);
        setCategoryId(0);
    };

    const containerRef = useRef(null);
    const [scrollIntervalLeft, setScrollIntervalLeft] = useState(null);
    const [scrollIntervalRight, setScrollIntervalRight] = useState(null);

    const startScrollLeft = () => {
        const interval = setInterval(() => {
            containerRef.current.scrollBy({
                left: -150, // Adjust this value for scroll speed
                behavior: 'smooth'
            });
        }, 50); // Adjust this value for scroll speed
        setScrollIntervalLeft(interval);
    };

    const stopScrollLeft = () => {
        clearInterval(scrollIntervalLeft);
    };

    const startScrollRight = () => {
        const interval = setInterval(() => {
            containerRef.current.scrollBy({
                left: 150, // Adjust this value for scroll speed
                behavior: 'smooth'
            });
        }, 50); // Adjust this value for scroll speed
        setScrollIntervalRight(interval);
    };

    const stopScrollRight = () => {
        clearInterval(scrollIntervalRight);
    };

    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    return (
        <div className='flex flex-row w-[100%]' style={{ width: '100%' }}>
            <div className="flex items-center w-[10%]">
                <button onMouseDown={startScrollLeft} onMouseUp={stopScrollLeft} onMouseLeave={stopScrollLeft} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2">&lt;</button>
            </div>
            <div className="overflow-x-hidden flex flex-row px-5 w-[80%}" ref={containerRef}>
                <button
                    onClick={()=>setCategoryId(0) & handleCategoryClick(null)}  
                    className={`px-5 py-1 rounded-2xl text-white text-sm mr-4 ${selectedCategory === null ? 'bg-yellow-500' : 'bg-gray-500'}`}
                >
                    All Categories
                </button>
                {
                    categories &&
                    categories.map((items, index) => {
                        return (
                            <button onClick={() => ChangeValue(items) & handleCategoryClick(items.category_name)} key={index} className={`px-5 py-1 rounded-2xl text-white text-sm mr-4 ${selectedCategory === items.category_name ? 'bg-yellow-500' : 'bg-gray-500'}`}>
                                {items?.category_name}
                            </button>
                        )
                    })
                }
            </div>
            <div className="flex items-center w-[10%] align-middle pl-3">
                <button onMouseDown={startScrollRight} onMouseUp={stopScrollRight} onMouseLeave={stopScrollRight} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2">&gt;</button>
            </div>
            {/* <Select
                options={categories}
                placeholder="All Categories"
                loading = {loading}
                searchBy="category_name"
                searchable={true}
                labelField="category_name"
                valueField="id"
                onChange={(values) => ChangeValue(values)}
                style={{ width: 220, fontSize: 14, borderColor: '#9333EA', borderWidth: 2, paddingLeft: 10, borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }} />
            <button type="button" onClick={ResetAll} className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800  font-medium text-sm px-5 py-2  dark:bg-purple-600 dark:hover:bg-purple-700"
                style={{ marginLeft: -2, borderTopRightRadius: 8, borderBottomRightRadius: 8 }}
            >
                <GrPowerReset />
            </button> */}
        </div>

    )
}

export default Categories;