import React, { useEffect, useState } from "react";
import { IoIosSave } from "react-icons/io";
import { FaPrint } from "react-icons/fa6";
import FinalSave from "../../services/pos/finalSave";
import { DateAndTime } from "../dateAndTime";
import toast, { Toaster } from 'react-hot-toast';
import PrintButton from "../../services/pos/printInvoice";
import { RotatingSquare } from 'react-loader-spinner';

const PaymentAll = ({ setPayAll, AllData, cart, resteAll }) => {

    

    const [amount, setAmounts] = useState('');
    const [paymentNote, setPaymentNote] = useState("");
    const [date, setDate] = useState('')
    const [loading, setLoading] = useState(false);

    const [totalPaying, setTotalPaying] = useState(0);
    const [balance, setBalance] = useState(AllData.lastTotalPrice.toFixed(2));
    const [change, setChange] = useState(0);
    const setAmount = (text) => {
        setAmounts(text);
        if (text !== '') {
            setTotalPaying(text);
            if (text < AllData.lastTotalPrice) {
                const B = AllData.lastTotalPrice - text;
                let temp2 = B.toFixed(2);
                setBalance(temp2);
                const A = 0;
                let temp = A.toFixed(2);
                setChange(temp);
            }
            else {
                const C = 0;
                let temp3 = C.toFixed(2);
                setBalance(temp3);
                const D = text - AllData.lastTotalPrice;
                let temp4 = D.toFixed(2);
                setChange(temp4);
            }
        } else {
            const E = 0;
            let temp5 = E.toFixed(2);
            setTotalPaying(temp5);
            setBalance(AllData.lastTotalPrice.toFixed(2));
            setChange(temp5);
        }
    };



    useEffect(() => {
        let DataT = localStorage.getItem("AllData22");
        const LogData = JSON.parse(DataT);

        let C_Data = localStorage.getItem("CompanyDetails");
        const CompanyD = JSON.parse(C_Data);

        const config = {
            headers: { 'x-pos-netbees-token': `Basic ${LogData.token}` }
        };

        

        DateAndTime((dt) => {
            setDate(dt.date)
        })

    }, []);

    const F_Save = () => {
        if (balance !== "0.00") {
            toast.error('Should Pay complete Amount');
        } else {
            FinalSave(paymentNote, AllData, cart, date, resteAll)
        }
    };

    const SaveAndPrint = async () => {

        if (balance !== "0.00") {
            toast.error('Should Pay complete Amount');
        } else {
            setLoading(true);
            FinalSave(paymentNote, AllData, cart, date, resteAll);
        }
    }

    return (
        <><div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
            {
                loading &&
                <RotatingSquare
                    visible={loading}
                    height="70"
                    width="70"
                    color="#4fa94d"
                    ariaLabel="rotating-square-loading"
                    radius="12.5"
                    wrapperStyle={{ width: "100%", height: "100%", position: 'absolute', backgroundColor: 'rgb(0,0,0, 0.6)', alignItems: 'center', justifyContent: 'center', zIndex: 55 }}
                    wrapperClass="grid-wrapper"
                />
            }
            <div className="relative w-[70%] my-1 ">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div style={{ paddingLeft: 100, paddingTop: 10 }} className="bg-gradient-to-r from-purple-500 to-purple-900 flex items-start justify-between border-b border-solid border-blueGray-200 rounded-t">
                        <h3 className="text-white w-full text-center font-semibold">
                            Payment
                        </h3>
                        <button
                            className="p-1 ml-auto bg-red-600 border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"

                        >
                            <span className=" text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                ×
                            </span>
                        </button>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex flex-row w-[100%]">
                        <div className="leading-relaxed w-[40%]">
                            <p style={{ borderBottomWidth: 1, borderColor: '#fff' }} className="leading-relaxed w-[100%] flex flex-row">
                                <p style={{ borderTopLeftRadius: 10 }} className=" text-white  p-2 bg-[#3C8DBC] text-blueGray-500 text-lg leading-relaxed w-[50%]">
                                    Total Items:
                                </p>
                                <p className=" text-white  p-2 bg-[#3C8DBC] text-blueGray-500 text-lg font-bold text-right leading-relaxed w-[50%]">
                                    {AllData.totalQuantity}
                                </p>
                            </p>
                            <p style={{ borderBottomWidth: 1, borderColor: '#fff' }} className="leading-relaxed w-[100%] flex flex-row">
                                <p className=" text-white  p-2 bg-[#3C8DBC] text-blueGray-500 text-lg leading-relaxed w-[50%]">
                                    Total:
                                </p>
                                <p className=" text-white  p-2 bg-[#3C8DBC] text-blueGray-500 text-lg font-bold text-right leading-relaxed w-[50%]">
                                    {AllData.totalPrice.toFixed(2)}
                                </p>
                            </p>
                            <p style={{ borderBottomWidth: 1, borderColor: '#fff' }} className="leading-relaxed w-[100%] flex flex-row">
                                <p className=" text-white  p-2 bg-[#3C8DBC] text-blueGray-500 text-lg leading-relaxed w-[50%]">
                                    Discount(-):
                                </p>
                                <p className=" text-white  p-2 bg-[#3C8DBC] text-blueGray-500 text-lg font-bold text-right leading-relaxed w-[50%]">
                                    {AllData.totalDiscount.toFixed(2)}
                                </p>
                            </p>
                            <p style={{ borderBottomWidth: 1, borderColor: '#fff' }} className="leading-relaxed w-[100%] flex flex-row">
                                <p className=" text-white  p-2 bg-[#FB2E00] text-blueGray-500 text-lg leading-relaxed w-[50%]">
                                    Total Payable:
                                </p>
                                <p className=" text-white  p-2 bg-[#FB2E00] text-blueGray-500 text-lg font-bold text-right leading-relaxed w-[50%]">
                                    {AllData.lastTotalPrice.toFixed(2)}
                                </p>
                            </p>
                            <p style={{ borderBottomWidth: 1, borderColor: '#fff' }} className="leading-relaxed w-[100%] flex flex-row">
                                <p className=" text-white  p-2 bg-[#3C8DBC] text-blueGray-500 text-lg leading-relaxed w-[50%]">
                                    Total Paying:
                                </p>
                                <p className=" text-white  p-2 bg-[#3C8DBC] text-blueGray-500 text-lg font-bold text-right leading-relaxed w-[50%]">
                                    {totalPaying}
                                </p>
                            </p>
                            <p style={{ borderBottomWidth: 1, borderColor: '#fff' }} className="leading-relaxed w-[100%] flex flex-row">
                                <p className=" text-white  p-2 bg-[#3C8DBC] text-blueGray-500 text-lg leading-relaxed w-[50%]">
                                    Balance:
                                </p>
                                <p className=" text-white  p-2 bg-[#3C8DBC] text-blueGray-500 text-lg font-bold text-right leading-relaxed w-[50%]">
                                    {balance}
                                </p>
                            </p>
                            <p className="leading-relaxed w-[100%] flex flex-row">
                                <p style={{ borderBottomLeftRadius: 10 }} className=" text-white  p-2 bg-[#FB9100] text-blueGray-500 text-lg leading-relaxed w-[50%]">
                                    Change Return:
                                </p>
                                <p className=" text-white  p-2 bg-[#FB9100] text-blueGray-500 text-lg font-bold text-right leading-relaxed w-[50%]">
                                    {change}
                                </p>
                            </p>
                        </div>
                        <div style={{ borderTopRightRadius: 10, borderBottomRightRadius: 10 }} class="flex flex-col items-end gap-6 w-[60%] p-4 bg-gray-300">
                            <div class="relative h-10 w-full min-w-[200px]">
                                <input
                                    type='number'
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    class="peer bg-white h-full w-full rounded-[7px] border border-green-500 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-green-500 placeholder-shown:border-t-green-500 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                                    placeholder=" " />
                                <label
                                    class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-green-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-green-500 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-green-500 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-green-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    Amount
                                </label>
                            </div>
                            <div class="relative h-10 w-full min-w-[200px]">
                                <textarea
                                    class="peer bg-white h-full min-h-[100px] w-full resize-none rounded-[7px] border border-green-500 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-green-500 placeholder-shown:border-t-green-500 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 disabled:resize-none disabled:border-0 disabled:bg-blue-gray-50"
                                    placeholder=" "
                                    value={paymentNote}
                                    onChange={(e) => setPaymentNote(e.target.value)}
                                ></textarea>
                                <label
                                    class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-green-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-green-500 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-green-500 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-green-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    Payment Note
                                </label>
                            </div>
                        </div>
                    </div>
                    {/*footer*/}
                    <div className="flex ml-5 items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            class="select-none rounded-lg bg-red-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                            type="button"
                            onClick={() => setPayAll(false)}
                        >
                            Close
                        </button>
                        <button
                            class="select-none flex flex-row ml-5 rounded-lg bg-blue-500 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-blue-500/20 transition-all hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                            type="button"
                            onClick={F_Save}
                        >
                            <IoIosSave style={{ margin: 2 }} /> Save
                        </button>
                        <PrintButton
                            SaveAndPrint={SaveAndPrint}
                            balance={balance}
                            setPayAll={setPayAll}
                        />
                    </div>
                </div>
            </div>
        </div><div className="opacity-25 fixed inset-0 z-40 bg-black"></div></>

    )
};

export default PaymentAll;