import React, { useEffect, useState } from "react";
import { BrowserRouter as Navigate } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { isLoged } from "../Login/inedx";
import ProductsUi from "../../components/posComponents/productsUI";
import LeftUi from "../../components/posComponents/leftUi";
import * as Constants from "../../api/constants";
import { get } from "../../api/apiServices";
import { useTranslation } from 'react-i18next';
import { RotatingSquare } from 'react-loader-spinner';

const PosScreeen = () => {
    const navigate = useNavigate();

    const [enableUI, setEnabledUI] = useState(false);
    const [products, setProducts] = useState(false);
    const [loading, setLoading] = useState(true);
    const [currency_code, setCurrencyCode] = useState("$");
    const [defaultwarehouseId, setDefaultwarehouseId] = useState(1);
    const [m_config, setM_Config] = useState([]);
    const [m_logD, setM_LogD] = useState([]);
    const [category_id, setCategoryId] = useState(0);
    const [brand_id, setBrandId] = useState(0);
    const [cart, setCart] = useState([]);

    useEffect(() => {

        let DataT = localStorage.getItem("AllData22");

        if (DataT === false || DataT === undefined || DataT === null) {
            navigate("/");
        } else {
            const LogData = JSON.parse(DataT);
            console.log(DataT)
            let C_Data = localStorage.getItem("CompanyDetails");
            const CompanyD = JSON.parse(C_Data);
            CompanyD?.map((items) => {
                setCurrencyCode(items.currency_code)
            })
            const config = {
                headers: { 'x-pos-netbees-token': `Basic ${LogData?.token}` }
            };
            setM_Config(config);
            setM_LogD(LogData);
            fetchProducts(config, LogData)
            fetchHoldItems(config, LogData);
        }
    }, []);

    const [holdList, setHoldList] = useState([]);
    const [holdcount, setHoldcount] = useState(0);


    const fetchHoldItems = async (config, LogData) => {
        get(Constants.GET_HOLD_ALL_ITEMS + defaultwarehouseId + '/' + LogData.company_id, config)
            .then(({ data }) => {
                setHoldList(data.result);
            })
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        fetchHoldItems(m_config, m_logD);
        setHoldcount(holdList.length);
    }, [holdList])

    useEffect(() => {
        fetchProducts(m_config, m_logD);
    }, [defaultwarehouseId]);

    useEffect(() => {
        fetchProducts(m_config, m_logD);
    }, [category_id]);

    useEffect(() => {
        fetchProducts(m_config, m_logD);
    }, [brand_id]);

    const fetchProducts = async (config, LogData) => {
        setLoading(true);
        get(Constants.GET_ITEMS + LogData.company_id + "/" + defaultwarehouseId + "/" + category_id + "/" + brand_id + "/" + 0 + '/' + 1, config)
            .then(({ data }) => {
                console.log(data.result);
                setProducts(data.result);
                setTimeout(() => {
                    setLoading(false);
                    setEnabledUI(true)
                }, 1500);
            })
            .catch((error) => console.error(error));
    };

    const { i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };


    return (

        <div className="container mx-auto bg-white">

            <div className="flex lg:flex-row flex-col-reverse shadow-lg">

                {enableUI ? (
                    <LeftUi
                        setDefaultwarehouseId={setDefaultwarehouseId}
                        changeLanguage={changeLanguage}
                        cart={cart}
                        setCart={setCart}
                        defaultwarehouseId={defaultwarehouseId}
                        currency_code={currency_code}
                    />
                ) : (
                    <RotatingSquare
                        visible={loading}
                        height="80"
                        width="80"
                        color="#006AB8"
                        ariaLabel="rotating-square-loading"
                        radius="12.5"
                        wrapperStyle={{ width: "95%", height: "100%", position: 'absolute', backgroundColor: '#fff', alignItems: 'center', justifyContent: 'center', zIndex: 999 }}
                        wrapperClass="grid-wrapper "
                    />
                )}
                {
                    enableUI &&
                    <ProductsUi
                        products={products}
                        currency_code={currency_code}
                        loading={loading}
                        setCategoryId={setCategoryId}
                        setBrandId={setBrandId}
                        m_logD={m_logD}
                        i18n={i18n}
                        cart={cart}
                        setCart={setCart}
                        changeLanguage={changeLanguage}
                        holdList={holdList}
                        holdcount={holdcount}
                    />
                }

            </div>
        </div >
    );
};

export default PosScreeen;