import React, { useState, useEffect } from 'react';
import Select from "react-dropdown-select";
import { get } from '../../api/apiServices';
import * as Constants from "../../api/constants";
import { FaWarehouse } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const WareHouse = ({setDefaultwarehouseId}) => {

    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const [defaultwarehouse, setDefaultwarehouse] = useState("Default Warehouse");
    
    const [menuItems, setMenuItems] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [val, setValues] = useState([]);
    const [showButton, setShowButton] = useState(true)

    useEffect(() => {
        let DataT = localStorage.getItem("AllData22");
        const LogData = JSON.parse(DataT);
        const config = {
            headers: { 'x-pos-netbees-token': `Basic ${LogData.token}` }
        };
        fetchwarehouse(config, LogData);

    }, []);

    const fetchwarehouse = async (config, LogData) => {

        get(Constants.GET_WAREHOUSE + LogData.company_id, config)
            .then(({ data }) => {
                console.log(data.result);
                const DataW = data.result;
                DataW.unshift({ id: 0, warehouse_name: "--(All Warehouses)--" });
                setMenuItems(DataW);
                fetchDefaultwarehouse(config, LogData, DataW);
            })
            .catch((error) => console.error(error))
    };

    const fetchDefaultwarehouse = async (config, LogData, DataW) => {
        get(Constants.GET_DEFAULT_WAREHIUSE + + LogData.company_id, config)
            .then(({ data }) => {
                console.log(data.result)
                data.result.map((item) => {
                    DataW.map((item2) => {
                        if (item.id === item2.id) {
                            setDefaultwarehouse(item2.warehouse_name)
                        }
                    })
                    setDefaultwarehouseId(item.id)
                })
            })
            .catch((error) => console.error(error))
    };

    const ChangeValue =(values)=>{
        values.forEach((items)=>{
            setDefaultwarehouseId(items.id)
        })
    }


    return (
        <>
            {showButton ? (<button type="button" onClick={() => setShowModal(true)} className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2  dark:bg-purple-600 dark:hover:bg-purple-700">
                <span className="flex flex-row">
                    <FaWarehouse style={{ marginRight: 5, marginTop: 2 }} />
                    {defaultwarehouse}
                </span></button>) : (<Select
                    options={menuItems}
                    placeholder={defaultwarehouse}
                    searchable={false}
                    labelField="warehouse_name"
                    valueField="id"
                    onChange={(values) => ChangeValue(values)}
                    style={{ width: 200, fontSize: 14, borderColor: '#9333EA', borderWidth: 2, borderRadius: 8, paddingLeft: 10 }}
                />)

            }

            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        {t('W_m')}
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                                        {t('W_d')}
                                    </p>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        {t('close')}
                                    </button>
                                    <button
                                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false) & setShowButton(false)}
                                    >
                                        {t('yes')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>


    );
}



export default WareHouse;