import moment from 'moment';

export const DateAndTime = (callback) => {
    var DateMoment = moment().utcOffset('+05:30')
        .format('YYYY-MM-DD');

    var TimeMoment = moment().utcOffset('+05:30')
        .format('HH:mm:ss');

        const Data = {date: DateMoment+" "+TimeMoment, }
        

        callback(Data);
}