

const onAdd = (item, index,cart, setCart) => {
    const nextProducts = [...cart];
    if (nextProducts[index].quantity < nextProducts[index].stock) {
        ++nextProducts[index].quantity;
        setCart(nextProducts);

        const AddPrice = [...cart];
        AddPrice[index].totalAmount += AddPrice[index].final_price;
        setCart(AddPrice);

        const AddDiscount = [...cart];
        AddDiscount[index].totalDiscount += AddDiscount[index].normalDiscount;
        setCart(AddDiscount);

        const AddTax = [...cart];
        AddTax[index].totalTax += AddTax[index].normalTax;
        setCart(AddTax);

        const AddUnitcost = [...cart];
        AddUnitcost[index].UnitCost += AddUnitcost[index].normalUnitCost;
        setCart(AddUnitcost);
    }
};

export default onAdd;