

const setCustomPrice = (text, index, cart, setCart) => {
    const number = parseFloat(text);

    //Discount Calculations -->
    const AddDiscount = [...cart];
    const newD = AddDiscount[index].normalDiscount;
    const BeforePrice = [...cart];
    const NewP = BeforePrice[index].productFinalPrice;
    const newUn = BeforePrice[index].normalUnitCost2;
    const newDis = NewP / newD;
    if (AddDiscount[index].discount_type == 'Percentage') {
        const AddDiscount2 = [...cart];
        AddDiscount2[index].totalDiscount = (newD * number) / NewP;
        setCart(AddDiscount2);
    }

    //end --<

    //Tax Calculations -->
    const AddTax = [...cart];
    const newTax = AddTax[index].normalTax;
    const AddTax2 = [...cart];
    AddTax2[index].totalTax = (newTax * number) / NewP;
    setCart(AddTax2);
    //end --<

    //totalUnitCost Calculate total -->
    const AddUnitcost2 = [...cart];
    AddUnitcost2[index].UnitCost = (newUn * number) / NewP;
    setCart(AddUnitcost2);

    //end --<

    const AddPrice = [...cart];
    AddPrice[index].final_price = number;
    setCart(AddPrice);

    const AddUnitcost = [...cart];
    AddUnitcost[index].normalUnitCost = (newUn * number) / NewP;
    setCart(AddUnitcost);

    const nextProducts = [...cart];
    nextProducts[index].totalAmount = number;
    setCart(nextProducts);



    if (text === '') {
        const nextProducts = [...cart];
        nextProducts[index].totalAmount = 0;
        setCart(nextProducts);

    }
    if (isNaN(text)) {
        const nextProducts = [...cart];
        nextProducts[index].totalAmount = 0;
        setCart(nextProducts);
    }

};

export default setCustomPrice;