import React, { useState, useEffect } from "react";
import { get, post } from "../../api/apiServices";
import * as Constants from "../../api/constants";
import { DateAndTime } from "../../components/dateAndTime";
import moment from 'moment';
import toast, { Toaster } from 'react-hot-toast';
import SaleItems from "./saleItems";
import PosPayment from "./posPayment";
import PosDelete from "./posDeleteSale";

const FinalSave = (paymentNote,AllData,cart,date,resteAll) => {

    var TimeMoment = moment().utcOffset('+05:30')
        .format('HH:mm:ss');

    let insertID = 0;
    let DataT = localStorage.getItem("AllData22");
        const LogData = JSON.parse(DataT);

        let C_Data = localStorage.getItem("CompanyDetails");
        const C_Details = JSON.parse(C_Data);
        
        const config = {
            headers: { 'x-pos-netbees-token': `Basic ${LogData.token}` }
        };
        
        try {
            post(Constants.POST_SALE, {
                sales_code: "SL00113",
                sales_date: date,
                reference_no: paymentNote,
                sales_status: "Final",
                customer_id: AllData.customer_id,//set customer id
                warehouse_id: AllData.warehouse_id, //set warehouse_id
                other_charges_input: 0, //other_charges_input set
                discount_to_all_input: 0, //set discount_to_all_input
                tot_discount_to_all_amt: 0, //set tot_discount_to_all_amt
                discount_to_all_type: "Fixed", //fix
                subtotal: AllData.lastTotalPrice.toFixed(2), //set subtotal
                round_off: 0, //fix
                grand_total: AllData.lastTotalPrice.toFixed(2), //set grand_total
                payment_status: 'Paid', //fix
                paid_amount: AllData.lastTotalPrice.toFixed(2), //set paid_amount
                created_date: date, //set date
                created_time: TimeMoment, //set time
                created_by: LogData.username, // set created_by
                system_ip: '192.168.10.12', //set ip
                system_name: "React-POS", //fix
                company_id: LogData.company_id, //set company_id
                pos: 1, //fix
                status: 1,//fix
                sales_user_id: LogData.id,
                sales_commission_rate: LogData.commission,
                loyalty_point_rate: C_Details.loyalty_point_percentage,
                loyalty_point_amount: 0,//0
                is_claim_added: 1,
                other_charges_input_clearance: 0,
                other_charges_input_transport: 0,
                other_charges_input_packing: 0,
                other_charges_input_labour: 0,
            }, config)
                .then(({ data }) => {
                    console.log(data.result)
                    insertID = data.result.insertId;
                    SaleItems(paymentNote,AllData,LogData, config, C_Details, insertID, cart, date,resteAll);
                    PosPayment(paymentNote,AllData,LogData, config, C_Details, insertID, date);
                    localStorage.setItem('InsertID',insertID);
                })
                .catch(function (error) {
                    alert('Error - FinalSave' + error);
                    PosDelete(LogData, config, C_Details , insertID);
                });
        } catch (error) {
            console.log(error);
        };
}

export default FinalSave;