
import { get, post } from "../../api/apiServices";
import * as Constants from "../../api/constants";
import toast, { Toaster } from 'react-hot-toast';
import PosDelete from "./posDeleteSale";

const SaleItems = (paymentNote, AllData, LogData, config, C_Details, insertID, cart, date, resteAll) => {
    const promises = cart.map((item) => {
        const A = item.sales_price;
        let pricePerunit = A.toFixed(2);
        const B = item.totalTax;
        let TaxAmount = B.toFixed(2);
        const C = item.totalDiscount;
        let DiscountAmount = C.toFixed(2);
        const D = item.normalUnitCost;
        let nUnitCost = D.toFixed(2);
        const E = item.UnitCost;
        let tUnitCost = E.toFixed(2);
        post(Constants.POST_SALE_ITEM, {
            saleId: insertID, //set saleId
            sales_status: "Final", //fix
            item_id: item.id, //set item id
            description: item.description, //set item description
            sales_qty: item.quantity, //set sales_qty
            price_per_unit: pricePerunit, //set price_per_unit
            tax_type: item.tax_type, // set Exclusive
            tax_id: item.tax_id, //set tax_id
            tax_amt: TaxAmount, //set tax_amt
            discount_type: item.discount_type,// set Percentage
            discount_input: item.discount, //set discount_input
            discount_amt: DiscountAmount, //set discount_amt
            unit_total_cost: nUnitCost, //set unit_total_cost
            total_cost: tUnitCost, //set total_cost
            purchase_price: item.sales_price, //set purchase_price
            status: 1, //fix
            warehouse_id: AllData.warehouse_id,
            sales_unit_id: item.sales_unit_id,  //set warehouse id 445
        }, config);

    });

    Promise.all(promises)
        .then(({ data }) => {
            console.log(data);
            resteAll();
            toast.success('Invoice Successfully Saved');
        })
        .catch(function (error) {
            alert('Error - SaleItems' + error);
            toast.error('Something went wrong please try again');
            PosDelete(LogData, config, C_Details);
        })
};

export default SaleItems;