import React, { useState, useEffect } from 'react';
import Select from "react-dropdown-select";
import { get } from '../../api/apiServices';
import * as Constants from "../../api/constants";

const Customers = ({setCustomer_id,customer_id}) => {

    const [customers, setCustomers] = useState([]);
    useEffect(() => {
        let DataT = localStorage.getItem("AllData22");
        const LogData = JSON.parse(DataT);
        const config = {
            headers: { 'x-pos-netbees-token': `Basic ${LogData.token}` }
        };
        fetchcustomers(config, LogData);

    }, []);

    const fetchcustomers = async (config, LogData) => {
        get(Constants.GET_CUSTOMERS + LogData.company_id, config)
            .then(({ data }) => {
                console.log(data.result)
                setCustomers(data.result)
            })
            .catch((error) => console.error(error))
    };


    const ChangeValue =(values)=>{
        values.forEach((items)=>{
            setCustomer_id(items.id)
        })
    }

    return (
        <Select
            options={customers}
            placeholder= "Walk-in customer"
            searchBy="customer_name"
            searchable={true}
            labelField="customer_name"
            valueField="id"
            onChange={(values) => ChangeValue(values)}
            style={{ width: 200, fontSize: 14, borderColor: '#9333EA', borderWidth: 2, borderRadius: 8, paddingLeft: 10, }}
        />
    )
};

export default Customers;