import React, { useEffect, useState } from "react";
import { IoIosSave } from "react-icons/io";
import { FaPrint } from "react-icons/fa6";
import FinalSave from "../../services/pos/finalSave";
import { DateAndTime } from "../dateAndTime";
import toast, { Toaster } from 'react-hot-toast';
import PrintButton from "../../services/pos/printInvoice";
import { RotatingSquare } from 'react-loader-spinner';


const LeftUiPayment = ({ AllData,balance, setBalance,setAmounts,amounts,setChange,change }) => {

    const [paymentNote, setPaymentNote] = useState("");
    const [date, setDate] = useState('')
    const [loading, setLoading] = useState(false);

    const [totalPaying, setTotalPaying] = useState(0);
    
    const setAmount = (text) => {
        if (text !== '') {
            setTotalPaying(text);
            if (text < AllData.lastTotalPrice) {
                const B = AllData.lastTotalPrice - text;
                let temp2 = B.toFixed(2);
                setBalance(temp2);
                const A = 0;
                let temp = A.toFixed(2);
                setChange(temp);
            }
            else {
                const C = 0;
                let temp3 = C.toFixed(2);
                setBalance(temp3);
                const D = text - AllData.lastTotalPrice;
                let temp4 = D.toFixed(2);
                setChange(temp4);
            }
        } else {
            const E = 0;
            let temp5 = E.toFixed(2);
            setTotalPaying(temp5);
            setBalance(AllData.lastTotalPrice.toFixed(2));
            setChange(temp5);
        }
    };



    useEffect(() => {
        let DataT = localStorage.getItem("AllData22");
        const LogData = JSON.parse(DataT);

        let C_Data = localStorage.getItem("CompanyDetails");
        const CompanyD = JSON.parse(C_Data);

        const config = {
            headers: { 'x-pos-netbees-token': `Basic ${LogData.token}` }
        };

        DateAndTime((dt) => {
            setDate(dt.date)
        })

    }, []);


    const handleChange = (e) => {
        // Ensure the value is converted to a number
        const value = parseInt(e.target.value);
        setAmount(isNaN(value) ? '' : value);
        setAmounts(isNaN(value) ? '' : value);
    };

    return (
            <div className="relative w-[100%] my-1 ">
                        <div className="relative p-3 flex flex-row w-[100%]">
                            <div class="relative h-10 w-full min-w-[200px]">
                                <input
                                    type='number'
                                    value={amounts}
                                    onChange={handleChange}
                                    class="peer bg-white h-full w-full border border-green-500 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-green-500 placeholder-shown:border-t-green-500 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                                    placeholder=" "
                                    style={{borderRadius: 0}} />
                                <label
                                    style={{borderRadius: 0}}
                                    class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-green-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-green-500 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:border-t after:border-green-500 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-green-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    Amount
                                </label>
                            </div>
                            <div class="relative h-9 w-full min-w-[200px]">
                            <p className=" text-white  p-1.5 bg-[#FB9100] text-blueGray-500 text-lg font-bold text-right leading-relaxed w-[100%]">
                                    Return: {change}
                                </p>
                            </div>
                        </div>
                    </div>
    )
};

export default LeftUiPayment;