import React, { useState, useEffect } from 'react';
import { BsTranslate } from "react-icons/bs";

const Languages = ({changeLanguage}) => {

    const [selectedItem, setSelectedItem] = useState("English");
    const [showDrop, setShowDrop] = useState(false);

    const menuItems = [
        { id: 1, label: "English", value:'en' },
        { id: 2, label: "Sinhala", value:'si' },
        { id: 3, label: "Tamil", value:'ta' }
    ]


    const handleMenuItemClick = (item) => {
        setSelectedItem(item.label);
        setShowDrop(false);
        changeLanguage(item.value);
    };

    const CloseM=()=>{
        setShowDrop(!showDrop);
    }

    return (
        <div className="relative inline-block text-left">
            <div>
                <button style={{ width: 90 }} onClick={CloseM} type="button" className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true">
                    <BsTranslate /> {selectedItem}
                </button>
            </div>
            {
                showDrop &&
                <div style={{ width: 90 }} className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                    <div className="py-1" role="none">
                        {menuItems.map((item, index) => (
                            <button key={index} onClick={() => handleMenuItemClick(item)} className={`text-gray-700 block px-4 py-2 text-sm ${selectedItem === item ? 'bg-gray-100 text-gray-900' : ''}`} role="menuitem" tabIndex="-1">
                                {item.label}
                            </button>
                        ))}
                    </div>
                </div>
            }

        </div>
    )
}

export default Languages;