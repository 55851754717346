import React, { useRef, useState, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import { get } from "../../api/apiServices";
import * as Constants from "../../api/constants";
import { FaPrint } from "react-icons/fa6";
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';



// Component for the content you want to print
const PrintableContent = React.forwardRef(({ comData, sData, saleItem }, ref) => {

    return (

        <div ref={ref} className="bg-white border rounded-lg shadow-lg px-6 py-8 max-w-md mx-auto mt-4">
            {comData &&
                comData.map((item) => {
                    return (
                        <><h1 class="font-bold text-lg my-4 text-center text-blue-600">{item?.company_name}</h1>
                            <div className="mb-1 text-center">
                                <p className="text-gray-700 font-bold text-center">
                                    Address Details
                                </p>
                                <p>{item?.address}, {item?.postcode}</p>
                                <p>Contact : {item?.mobile} / {item?.phone}</p>
                                <strong className="text-center">--------------Sales Invoice--------------</strong>
                            </div></>
                    )
                })
            }

            <div className="mb-8">
                {
                    sData &&
                    sData.map((item) => {
                        return (
                            <>
                                <div className="text-sm font-bold text-center">Invoice No : #{item?.sales_code}</div>
                                <h2 className="text-sm font-bold">Bill To: {item.customer_name}</h2>
                                <div className="text-gray-700 text-sm">Mobile No : {item?.mobile}</div>
                                <div className="text-gray-700 text-sm">Sales Agent : {item.created_by}</div>
                                <div className="text-gray-700 mb-2 text-sm">Sale Date :{item.sales_date} | Time : {item.created_time}</div>
                            </>
                        )
                    })
                }

            </div>
            <table className="w-full mb-8">
                <thead>
                    <tr className='border-dashed border-y-[1px] border-black '>
                        <th className="text-left text-sm font-bold text-gray-700"># {" "}Description</th>
                        <th className="text-right text-sm font-bold text-gray-700 pr-2">Qty</th>
                        <th className="text-right text-sm font-bold text-gray-700 pr-2">Amount</th>
                        <th className="text-right text-sm font-bold text-gray-700 pr-2">Total</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        saleItem &&
                        saleItem.map((item, index) => {
                            return (
                                <tr>
                                    <td className="text-left text-sm text-gray-700 pr-2">{index + 1} {item.item_name}</td>
                                    <td className="text-right text-sm text-gray-700 pr-2">{item.sales_qty}</td>
                                    <td className="text-right text-sm text-gray-700 pr-2">{item.price_per_unit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td className="text-right text-sm text-gray-700 pr-2">{item.total_cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                </tr>
                            )
                        })
                    }

                </tbody>
            </table>
            {
                sData &&
                sData.map((item) => {
                    return (
                        <div className='w-full text-right justify-end'>
                            <p className="w-full text-sm text-end text-gray-800 mt-4">Before Tax :{item?.before_tax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                            <p className="w-full text-sm text-right text-gray-800">Tax Amount :{item?.tax_amt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                            <p className="w-full text-sm text-right text-gray-800">Other Charges :{item?.other_charges_amt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                            <p className="w-full text-sm text-right text-gray-800">Tot Discount : :{item?.tot_discount_to_all_amt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                            <p className="w-full text-sm text-right font-bold text-gray-800 mt-2">Total :{item?.grand_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                            <p className="w-full text-sm text-right font-bold text-gray-800">Paid Amount :{item?.paid_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                        </div>
                    )
                })
            }
            <div className="text-gray-700 text-center text-sm mt-4 mb-2">-----------Thanks You, Visit Again-----------</div>
            <div className="text-gray-700 text-center text-sm">{"SmartPOS & Inventory, NetBees PVT LTD - Call/ Whatsapp >> +94774475196/ +6581352558"}</div>
        </div>
    );
});

const PrintButton = ({ SaveAndPrint, balance, setPayAll, cart }) => {


    const [invoiceData, setInvoiceData] = useState(null);
    const [comData, setComData] = useState(null);
    const [sData, setSData] = useState(null);
    const [saleItem, setSaleItem] = useState(null);
    


    let DataT = localStorage.getItem("AllData22");
    const LogData = JSON.parse(DataT);

    const config = {
        headers: { 'x-pos-netbees-token': `Basic ${LogData.token}` }
    };
    const componentRef = useRef();

    // Function to handle printing 
    const GetPdfData = () => {

        SaveAndPrint();
        if (cart.length === 0) {
            console.log('Please Add Product First');
        }
        else if (balance !== "0.00") {
            console.log('Should Pay complete Amount');
        } else {
            setTimeout(() => {
                let InsertID = localStorage.getItem("InsertID")
                get(Constants.GET_INVOICE + InsertID + '/' + LogData.company_id, config)
                    .then(({ data }) => {
                        console.log(data);
                        setComData(data.result.company_detail);
                        setSData(data.result.sale);
                        setSaleItem(data.result.saleitems);
                        setTimeout(() => {
                            handlePrint();
                            setPayAll(false);
                        }, 500);

                    })
                    .catch((error) => console.error(error));
            }, 4000);
        }


    }
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const { t } = useTranslation();

    return (
        <>
            <button class="relative w-[70%] inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white dark:text-white">
                <span onClick={GetPdfData} class="w-full relative px-4 py-4 transition-all ease-in duration-75 bg-green-600 dark:bg-green-600 rounded-md group-hover:bg-opacity-0">
                    {t('Pay_Now')}
                </span>
            </button>
            <div style={{ display: 'none' }}>
                <PrintableContent
                    ref={componentRef}
                    comData={comData}
                    sData={sData}
                    saleItem={saleItem}
                />
            </div>
        </>
    );
};

export default PrintButton;
