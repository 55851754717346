import React, { useState, useEffect } from "react";
import { get, post } from "../api/apiServices";
import { DateAndTime } from "./dateAndTime";
import * as Constants from "../api/constants";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";


const MultypleCompanys = ({ companys, u_name, passW, setLoading }) => {

    const navigate = useNavigate();
    const [date, setDate] = useState("");

    useEffect(() => {
        DateAndTime((dt) => {
            setDate(dt.date)
        })

    }, [])

    const loginCompany = (items) => {
        let cID = "";
        let Token = "";

        setLoading(true);
        post(Constants.POST_COMPANY_LOGIN, {
            username: u_name,
            password: passW,
            company_id: items.id,
            latitude: 6.922523045568294,
            longitude: 79.86582995480343,
            location: "Colombo, Srilanka",
            log_date: date,
        }).then(({ data }) => {
            console.log(data.result)
            cID = data.result.company_id;
            Token = data.result.token;
            CompanyDetails(cID, Token)
            localStorage.setItem('AllData11', data.result);
            setLoading(false);
            navigate("/pos");
        }).catch(error => {
            console.error(error + "Company-Login");
            setLoading(false);
            toast.error('Please try again');
        });
    };

    const CompanyDetails = (cID, Token) => {
        const config = {
            headers: { 'x-pos-netbees-token': `Basic ${Token}` }
        };
        get(Constants.GET_COMPANY_DETAILS + cID, config)
            .then(({ data }) => {
                console.log(data.result)
                localStorage.setItem('CompanyDetails', JSON.stringify(data.result));
            }).catch(error => console.error(error + "CompanyDetails-Login"));
    };

    return (
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                <img className=" w-96 mr-2" src="https://posdemo.netbees.com.sg/uploads/1/logo/1671261805.png" alt="logo" />
            </a>
            <div className="border rounded-md w-full content-center align-middle justify-center max-w-sm mx-1 bg-white p-6 border-violet-600 dark:bg-gray-900" id="login-model">
                <p className="text-2xl font-bold dark:text-white">Select to Continue</p>
                <p className="dark:text-gray-200">Please select a company to log in</p>
                {   
                    companys &&
                    companys?.map((items) => {
                        return (<div className="mt-4">

                            <button className="w-full text-center py-2 my-3 border flex items-center justify-center border-slate-200 rounded-lg text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150"
                                onClick={() => loginCompany(items)}
                            >
                                {/* <img src="https://www.svgrepo.com/show/355037/google.svg" className="w-5 h-5 mr-2" alt="Google Icon" /> */}
                                <span className="dark:text-gray-300">{items?.company_name}</span>
                            </button>

                        </div>)
                    })
                }

            </div>
        </div>
    )

};

export default MultypleCompanys;