// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './translationEn.json';
import translationSi from './translationSi.json';
import translationTa from './translationTa.json';

const resources = {
    en: {
        translation: translationEN,
    },
    si: {
        translation: translationSi,
    },
    ta: {
        translation: translationTa,
    },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'en', // default language
        fallbackLng: 'en',

        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
