
const AddItemsToList = async (cart,setCart,item) => {
    if (item.stock !== 0) {
        let findProductInCart = cart.find(i => {
            return i.id === item.id
            // setSearching(false);
        });

        if (findProductInCart) {
            let newCart = [];
            let newItem;

            cart.forEach(cartItem => {
                if (cartItem.id === item.id) {
                    newItem = {
                        ...cartItem,
                        quantity: ++cartItem.quantity,
                        totalAmount: cartItem.final_price * (cartItem.quantity),
                        totalTax: cartItem.totalTax + cartItem.normalTax,
                        totalDiscount: cartItem.totalDiscount + cartItem.normalDiscount,
                        UnitCost: cartItem.UnitCost + cartItem.normalUnitCost,
                    }
                    newCart.push(newItem);
                    // setSearching(false);
                    // setSearch('');

                } else {
                    newCart.push(cartItem);
                    // setSearching(false);
                    // setSearch('');
                }
            });

            setCart(newCart);

        } else {
            if (item.tax_type == 'Exclusive') {
                if (item.discount_type == 'Percentage') {
                    let addingProduct = {
                        ...item,
                        'quantity': 1,
                        'totalAmount': item.final_price,
                        'productFinalPrice': item.final_price,
                        'totalTax': (item.sales_price - (item.sales_price * item.discount / 100)) * (item.tax / 100),
                        'normalTax': (item.sales_price - (item.sales_price * item.discount / 100)) * (item.tax / 100),
                        'totalDiscount': item.sales_price * item.discount / 100,
                        'normalDiscount': item.sales_price * item.discount / 100,
                        'UnitCost': (item.sales_price - (item.sales_price * item.discount / 100)) + (item.sales_price - (item.sales_price * item.discount / 100)) * (item.tax / 100),
                        'normalUnitCost': (item.sales_price - (item.sales_price * item.discount / 100)) + (item.sales_price - (item.sales_price * item.discount / 100)) * (item.tax / 100),
                        'normalUnitCost2': (item.sales_price - (item.sales_price * item.discount / 100)) + (item.sales_price - (item.sales_price * item.discount / 100)) * (item.tax / 100),
                        'Holdid': "null",
                    }
                    setCart([...cart, addingProduct]);
                    // setSearching(false);
                    // setSearch('');
                }
                else if (item.discount_type == 'Fixed') {
                    let addingProduct = {
                        ...item,
                        'quantity': 1,
                        'productFinalPrice': item.final_price,
                        'totalAmount': item.final_price,
                        'totalTax': (item.sales_price - item.discount) * item.tax / 100,
                        'normalTax': (item.sales_price - item.discount) * item.tax / 100,
                        'totalDiscount': item.discount,
                        'normalDiscount': item.discount,
                        'UnitCost': (item.sales_price - item.discount) + ((item.sales_price - item.discount) * item.tax / 100),
                        'normalUnitCost': (item.sales_price - item.discount) + ((item.sales_price - item.discount) * item.tax / 100),
                        'normalUnitCost2': (item.sales_price - item.discount) + ((item.sales_price - item.discount) * item.tax / 100),
                        'Holdid': "null",
                    }
                    setCart([...cart, addingProduct]);
                    // setSearching(false);
                    // setSearch('');
                }

            }
            else if (item.tax_type == 'Inclusive') {
                if (item.discount_type == 'Percentage') {
                    let addingProduct = {
                        ...item,
                        'quantity': 1,
                        'productFinalPrice': item.final_price,
                        'totalAmount': item.final_price,
                        'totalTax': (item.sales_price - (item.sales_price * item.discount / 100)) / ((item.tax / 100) + 1) / 10,
                        'normalTax': (item.sales_price - (item.sales_price * item.discount / 100)) / ((item.tax / 100) + 1) / 10,
                        'totalDiscount': item.sales_price * item.discount / 100,
                        'normalDiscount': item.sales_price * item.discount / 100,
                        'UnitCost': item.sales_price - (item.sales_price * item.discount / 100),
                        'normalUnitCost': item.sales_price - (item.sales_price * item.discount / 100),
                        'normalUnitCost2': item.sales_price - (item.sales_price * item.discount / 100),
                        'Holdid': "null",
                    }
                    setCart([...cart, addingProduct]);
                    // setSearching(false);
                    // setSearch('');
                }
                else if (item.discount_type == 'Fixed') {
                    let addingProduct = {
                        ...item,
                        'quantity': 1,
                        'totalAmount': item.final_price,
                        'productFinalPrice': item.final_price,
                        'totalTax': ((item.sales_price - item.discount) / ((item.tax / 100) + 1) / 10),
                        'normalTax': ((item.sales_price - item.discount) / ((item.tax / 100) + 1) / 10),
                        'totalDiscount': item.discount,
                        'normalDiscount': item.discount,
                        'UnitCost': item.sales_price - item.discount,
                        'normalUnitCost': item.sales_price - item.discount,
                        'normalUnitCost2': item.sales_price - item.discount,
                        'Holdid': "null",
                    }
                    setCart([...cart, addingProduct]);
                    // setSearching(false);
                    // setSearch('');
                }

            }
        }
    }
    else {
        alert(item.item_name + ' is OUT of stock');
    }
}

export default AddItemsToList;