import React, { useState, useEffect } from 'react';
import { CiBarcode } from "react-icons/ci";
import { get } from '../api/apiServices';
import * as Constants from "../api/constants";

const ProdutSearch = ({defaultwarehouseId,setSearching,setFiltered,setSearch,search}) => {

    const [sProducts, setSProducts] = useState([]);
    const [m_config, setM_Config] = useState([]);
    const [m_logD, setM_LogD] = useState([]);
    

    useEffect(() => {
        let DataT = localStorage.getItem("AllData22");
        const LogData = JSON.parse(DataT);
        const config = {
            headers: { 'x-pos-netbees-token': `Basic ${LogData.token}` }
        };
        setM_Config(config);
        setM_LogD(LogData);
    }, []);

    const onSearch = (text) => {
        setSearch(text);
        if (text !== '') {
            
            get(Constants.GET_SEARCH_ITEMS + m_logD.company_id + "/" + defaultwarehouseId + "/" + text + "/" + 1, m_config)
                .then(({ data }) => {
                    setSearching(true)
                    setFiltered(data.result);
                    const filtered = data.result;

                    filtered.map(item => {
                        if (text === item.sku) {
                            setSearching(false)
                            setSearch('');
                        }
                    })

                })
                .catch((error) => console.error(error))
            setFiltered([]);
        }
        else if (text == '') {
            setSearching(false)
            setFiltered([]);
        }
        else {
            setSearching(false)
            setFiltered([]);
        }
    };

    return (

        <div className=" w-full">
            <div className="relative mb-4 flex w-full flex-wrap items-stretch">
                <button type="button" className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800  font-medium text-sm px-5 py-2  dark:bg-purple-600 dark:hover:bg-purple-700"
                    style={{ marginLeft: -2, borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}
                >
                    <CiBarcode />
                </button>
                <input
                    type="search"
                    className="relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:focus:border-primary"
                    placeholder="Item Name/Barcode/Item Code/SKU/Number"
                    aria-label="Search"
                    value={search}
                    onChange={(text)=>onSearch(text.target.value)}
                    aria-describedby="button-addon1"
                    style={{ borderColor: '#9333EA', borderWidth: 2, paddingLeft: 10, borderTopRightRadius: 8, borderBottomRightRadius: 8 }} />

            </div>
        </div>


    )
}

export default ProdutSearch;

