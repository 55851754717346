
import { get, post } from "../../api/apiServices";
import * as Constants from "../../api/constants";
import toast, { Toaster } from 'react-hot-toast';


const PosDelete = (LogData, config, C_Details , insertID) => {
    post(Constants.POST_DELETE_SALE, {
        saleId: insertID,
    }, config)
        .then(({ data }) => {
            console.log(data.result)
            toast.error('Something went wrong please try again');
        })
        .catch(function (error) {
            alert('Error - PosDelete' + error);
            toast.error('Something went wrong please try again');
        });
};

export default PosDelete;